
import { Contact, useContactStore } from '@/stores/contact';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpContactEditorVue from '@/components/contact/BpContactEditor.vue';

export default defineComponent({
  name: 'contact-editor',
  components: {
    BpContactEditor: BpContactEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useContactStore();

    const contact = ref<Contact>();

    function loadContact() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        contact.value = doc;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadContact()
    }, { immediate: true });

    return {
      contact,
      store,
    }
  }
});
